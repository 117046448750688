<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">观察记录</h3>
    <div class="detail">
      <div class="flex  backReturn">
        <h4 class="title">观察记录复核</h4>
        <div>
          <!--          <el-button size="small" plain class="return">导出</el-button>-->
          <el-button size="small" plain class="return" @click="$emit('changePage', 1,null,3)">返回列表页</el-button>
        </div>
      </div>
      <table class="table specTable">
        <tr>
          <th colspan="3">方案编号</th>
          <td colspan="3">{{ activeInfo.number }}</td>
          <th colspan="3">动物品系</th>
          <td colspan="3">{{ activeInfo.number }}</td>
        </tr>
        <tr>
          <th>组别</th>
          <th>笼号</th>
          <th>动物编号</th>
          <th>性别</th>
          <th colspan="3">临床症状</th>
          <th colspan="3">备注</th>
        </tr>
        <template v-for="item in groupMap">
          <template v-for="(obj,index) in item">
            <tr v-for="(obj1,index1) in obj">
              <td :rowspan="obj.length" v-if="index1==0">{{ obj1.groupName }}</td>
              <td :rowspan="obj.length" v-if="index1==0">{{ obj1.cageNo }}</td>
              <td>{{ obj1.aniNo }}</td>
              <td>{{ obj1.gender == 1 ? '♂' : '♀' }}</td>
              <td colspan="3">
                <template v-if=" obj1.symptom==0">_ 正常</template>
                <template v-if=" obj1.symptom==1">① 死亡</template>
                <template v-if=" obj1.symptom==2">② 精神萎靡</template>
                <template v-if=" obj1.symptom==3">③ 活动减少</template>
                <template v-if=" obj1.symptom==4">④ 肿瘤红肿</template>
                <template v-if=" obj1.symptom==5">⑤ 肿瘤溃烂</template>
                <template v-if=" obj1.symptom==6">⑥ 结痂</template>
                <template v-if=" obj1.symptom==7">⑦ 其他</template>
                <template v-if=" obj1.symptom==8">NA 已死亡</template>
              </td>
              <td colspan="3">{{ obj1.remark }}</td>
            </tr>
          </template>
        </template>
        <tr>
          <td colspan="2">操作人</td>
          <td colspan="3">{{ observationInfo.createName }}</td>
          <td colspan="2">日期</td>
          <td colspan="3">{{ observationInfo.createTime | formatDay }}</td>
        </tr>
        <tr>
          <td colspan="2">复核人</td>
          <td colspan="3">{{ observationInfo.reviewName }}</td>
          <td colspan="2">日期</td>
          <td colspan="3">{{ observationInfo.reviewDate | formatDay }}</td>
        </tr>
      </table>
      <template v-if="endDetailList.length>0">
        <h4 class="title">动物尸体处理复核</h4>
        <table class="table specTable">
          <tr>
            <th colspan="3">方案编号</th>
            <td colspan="3">{{ activeInfo.number }}</td>
            <th colspan="3">动物品系</th>
            <td colspan="3">{{ activeInfo.strain }}</td>
          </tr>
          <tr>
            <th>组别</th>
            <th>动物编号</th>
            <th>耳号</th>
            <th>性别</th>
            <th>处理方式</th>
            <th colspan="2">解剖观察</th>
            <th colspan="3">备注</th>
          </tr>
          <tr v-for="item in endDetailList">
            <td>{{ item.groupName }}</td>
            <td>{{ item.aniNo }}</td>
            <td>{{ item.overbit }}</td>
            <td>{{ item.gender == 1 ? '♂' : '♀' }}</td>
            <td>{{ item.handle == 0 ? '回收箱丢弃' : '解剖处理' }}</td>
            <td colspan="2">{{ item.dissection }}</td>
            <td colspan="3">{{ item.remark }}</td>
          </tr>
        </table>
      </template>
      <template v-if="state==1&&checkState">
        <h4 class="title">复核结果</h4>
        <el-form :model="measureForm" ref="measureForm" size="small" class="addForm" label-suffix=":"
                 label-width="90px">
          <el-form-item label="复核结果">
            <el-radio-group v-model="measureForm.state" class="radioGroup">
              <el-radio :label="2">复核通过</el-radio>
              <el-radio :label="3">返回修改(复核意见必填)</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="复核意见" prop="reviewRemark"
                        :rules="validateType">
            <el-input type="textarea" rows="4" placeholder="请输入复核意见" v-model="measureForm.reviewRemark"></el-input>
          </el-form-item>
          <div class="fromSave">
            <el-button type="primary" size="small" @click="checkSubmit('measureForm')">复核完成</el-button>
          </div>
        </el-form>
      </template>
      <template v-else-if="state==0||state==1&&!checkState"></template>
      <template v-else>
        <h4 class="title">复核结果</h4>
        <div class="desc">
          <p>复核结果：{{ observationInfo.state == 2 ? '复核通过' : observationInfo.state == 3 ? '复核拒绝' : '' }}</p>
          <p>复核意见：{{ observationInfo.reviewRemark }}</p>
          <span>复核人：{{ observationInfo.reviewName }}</span>
          <span>复核日期：{{ observationInfo.reviewDate | formatDay }}</span>
        </div>
      </template>
    </div>
  </div>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: "observation",
  props: ["id", "observeId"],
  data() {
    return {
      activeInfo: {},
      state: null,
      groupMap: {},
      observationInfo: {},
      endDetailList: [],
      checkState: false,
      measureForm: {
        state: 2,
        reviewRemark: "",
      },
    }
  },
  mounted() {
    this.$get("/subject/" + this.id).then((res) => {
      if (res.status == 200) {
        this.activeInfo = res.data
      }
    })
    this.getData()
  },
  computed: {
    ...mapState({
      user: state => state.account.user
    }),
    validateType() {
      return this.measureForm.state === 3
          ? [{required: true, message: '请输入复核意见', trigger: 'blur'}]
          : []
    },
  },
  methods: {
    getData() {
      this.$get('/subject/observe/' + this.observeId).then(res => {
        if (res.status == 200) {
          this.state = res.data.observe.state
          for (const key in res.data.groupMap) {
            let obj = {}, array = [];
            res.data.groupMap[key].forEach(function (item) {
              array = obj[item['cageNo']] || [];
              array.push(item);
              obj[item['cageNo']] = array;
            });
            res.data.groupMap[key] = obj
            this.groupMap = res.data.groupMap
          }
          if (this.user.userId == res.data.observe.reviewer) {
            this.checkState = true
          }
          this.observationInfo = res.data.observe
          this.endDetailList = res.data.endDetailList
        }

      })
    },
    // 提交复核
    checkSubmit(checkForm) {
      this.$refs[checkForm].validate((valid) => {
        if (valid) {
          this.measureForm.id = this.observeId
          this.$put('/subject/observe/state', this.measureForm).then(res => {
            if (res.status == 200) {
              this.$message.success('提交成功')
              this.$emit('changePage', 1, null, 3)
            }
          })
        } else {
          return false;
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";

</style>
