import { render, staticRenderFns } from "./observation.vue?vue&type=template&id=3e67aabb&scoped=true&"
import script from "./observation.vue?vue&type=script&lang=js&"
export * from "./observation.vue?vue&type=script&lang=js&"
import style0 from "./observation.vue?vue&type=style&index=0&id=3e67aabb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e67aabb",
  null
  
)

export default component.exports